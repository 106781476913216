<template>
  <div class="home_order_confirm">
    <div class="title">确认订单</div>
    <table class="digital_info">
      <colgroup>
        <col width="30%">
        <col width="70%">
      </colgroup>
      <tr>
        <td rowspan="4" style="text-align:center"><img :src="order_info.thumb" class="thumb" /></td>
      </tr>
      <tr>
        <td class="title">&emsp;{{order_info.title}}</td>
      </tr>
      <tr>
        <td rowspan="2" class="desc">&emsp;
          {{order_info.desc}}
        </td>
      </tr>
    </table>
    <div class="address" v-if="order_info.item_type == 2">
      <div class="addr_title">收货地址</div>
      <div class="row addr_row">{{customer_info.customer_address}}</div>
      <div class="row"><span>{{customer_info.customer_name}}</span>{{customer_info.customer_phone}}</div>
    </div>
    <md-radio-list v-model="myBank" :options="banks" icon-size="lg" />
    <div class="price_info">
      合计：&yen;&nbsp;<md-amount :value="order_info.price" has-separator></md-amount>&nbsp;元
      <md-button class="commit_order_btn" type="primary" size="small" @click="commit_order" inline>提交订单</md-button>
    </div>
  </div>
</template>

<script>
import { Icon, Tag, Button, RadioList, Amount } from 'mand-mobile'


export default {
  name: "OrderConfirm",
  components: {
    [Icon.name]: Icon,
    [Tag.name]: Tag,
    [Button.name]: Button,
    [Amount.name]: Amount,
    [RadioList.name]: RadioList,
  },
  data() {
    return {
      'myBank': "",
      'customer_info': {},
      'order_info': {},
      'banks': [
        {
          value: '0',
          text: '和钱包',
        },
        {
          value: '1',
          text: '支付宝',
        },
        {
          value: '2',
          text: '微信',
        },
      ],
    }
  },
  methods: {
    buy_digital(item) {
      this.$parent.$parent.$parent.order_confirm(item)
    },
    set_order_info(order_info) {
      this.order_info = order_info
    },
    set_customer_info(customer_info) {
      this.customer_info = customer_info
    },
    set_my_bank(my_bank){
      this.myBank = my_bank
    },
    commit_order() {
      this.$parent.$parent.commit_order()
    }
  }
};
</script>

<style scoped>
.home_order_confirm {
  background: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  font-size: 32px;
  text-align: left;
  padding: 40px;
  padding-top: 0px;

}

.home_order_confirm .title {
  font-size: 46px;
  text-align: center;
  padding: 30px;
}

.home_order_confirm .price_info {
  font-size: 38px;
  margin-top: 50px;
}

.home_order_confirm .digital_info {
  width: 100%;
  text-align: left;
}

.home_order_confirm .digital_info .thumb {
  width: 180px;
  height: 180px;
  border-radius: 20px;
}

.home_order_confirm .digital_info .title {
  font-size: 32px;
  text-align: left;
  padding: 0px;
  border-bottom: none;
}

.home_order_confirm .digital_info .desc {
  font-size: 26px;
  line-height: 45px;
  color: #666f83;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  /** 对象作为伸缩盒子模型显示 **/
  -webkit-box-orient: vertical;
  /** 设置或检索伸缩盒对象的子元素的排列方式 **/
  -webkit-line-clamp: 3;
  /** 显示的行数 **/
  overflow: hidden;
  /** 隐藏超出的内容 **/
}

.home_order_confirm .commit_order_btn {
  float: right;
  margin-top: -20px;
}

.home_order_confirm .address {
  border: 1px solid #00af3b;
  padding: 20px;
  border-radius: 20px;
  margin: 10px 0px;
  line-height: 50px;
  font-size: 28px;
}

.home_order_confirm .address .addr_title {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 20px;
}

.home_order_confirm .address .row {
  padding-left: 40px;
  line-height: 50px;
}

.home_order_confirm .address .addr_row {
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  /** 对象作为伸缩盒子模型显示 **/
  -webkit-box-orient: vertical;
  /** 设置或检索伸缩盒对象的子元素的排列方式 **/
  -webkit-line-clamp: 1;
  /** 显示的行数 **/
  overflow: hidden;
  /** 隐藏超出的内容 **/
}

.home_order_confirm .address .row span {
  display: inline-block;
  margin-right: 20px;
}
</style>
