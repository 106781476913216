<template>
    <div class="my_header">
      <div class="message">
        <md-icon name='scan' size="lg"></md-icon>&emsp;
        <md-icon name='message' size="lg"></md-icon>
      </div>
    </div>
  </template>
  
  <script>
  import {Icon} from 'mand-mobile'
  
  export default {
    name: "Header",
    components: {
      [Icon.name]: Icon
    },
    props: {
    },
    methods: {
      greet() {
        Toast.info("I'm Batman!");
      }
    }
  };
  </script>
  
  <style scoped>
    .title, .message{
      display: inline-block;
      font-size: 35px;
      font-weight: bold;
    }
    .title{
      float: left;
    }

    .my_header{
      padding: 30px;
      position: fixed;
      top: 0px;
      left: 0px;
      right: 0px;
      z-index: 500;
      background-color: rgba(255, 255, 255, 0.8);
    }

    .message{
      float: right;
    }
    .message .md-icon{
        margin-right: 20px;
    }
    .message .md-icon:first-child{
        margin-right: 0px;
    }
  </style>
  