<template>
  <div class="digital_detail">
    <div class="title">藏品详情</div>
    <img :src="digital_info.thumb" class='thumb' />
    <div class="digital_title">{{digital_info.title}}</div>
    <div class="quantity">
      <span>限量</span>
      <span>{{digital_info.quantity}}份</span>
    </div>
    <div class="block_chain_addr">
      <span>区块链地址：</span>
      <md-tag
        size="large"
        shape="fillet"
        fill-color="#00af3b"
        type="fill"
        font-color="#fff"
      >{{digital_info.block_chain_addr}}</md-tag>
    </div>
    <hr/>
    <div class="author_story">
      <div class="story_title">作品故事</div>
      <div class="story_content">&emsp;{{digital_info.author_story}}</div>
    </div>
  </div>
</template>

<script>
import { Icon, Tag, Button, RadioList, Amount } from 'mand-mobile'


export default {
  name: "DigitalDetail",
  components: {
    [Icon.name]: Icon,
    [Tag.name]: Tag,
    [Button.name]: Button,
    [Amount.name]: Amount,
    [RadioList.name]: RadioList,
  },
  data() {
    return {
      'digital_info': {
        'title': '查干湖冬捕',
        'thumb': '/static/images/cghdb.jpg',
        'quantity': 1000,
        'block_chain_addr': '33adEZZLecwxnVZH2XTzdff47fPyikcx89',
        'author_story': '◆《查干湖冬捕》习近平总书记到松原查干湖调研时强调，“一定要守住查干湖这块金字招牌”。近年来，松原市牢记嘱托，以生态为根、以文化为魂、以富民为本，以查干湖冰雪渔猎文化旅游节等活动为依托，促进了旅游产业快速发展。旅游产业的发展，也带动了相关产业发展和周边乡镇村民增收致富。通过打造民族特色冰雪旅游文化，举办各类民俗体验系列游等活动，使保护生态和发展生态旅游相得益彰。© 丁研 | 吉林省松原市，2020年'
      }
    }
  },
  methods: {

  }
};
</script>

<style scoped>
.digital_detail {
  background: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  font-size: 32px;
  text-align: left;
  padding: 40px;
  padding-top: 0px;

}
.digital_detail hr{
  border: hsl(200, 13%, 95%) 1px solid;
}

.digital_detail .title {
  font-size: 46px;
  text-align: center;
  padding: 30px;
}

.digital_detail .thumb {
  width: 100%;
  height: 100%;
  border-radius: 30px;
}

.digital_detail .digital_title{
  font-weight: bold;
  margin: 10px 0px;
  margin-left: 20px;
}
.digital_detail .quantity {
  font-size: 26px;
  width: 200px;
  padding: 10px;
  text-align: left;
  margin-left: 10px;
}

.digital_detail .quantity span {
  padding: 10px;
}

.digital_detail .quantity span:last-child {
  display: inline-block;
  background-color: #4c4c4e;
  color: #eee0c0;
}

.digital_detail .quantity span:first-child {
  display: inline-block;
  background: #eee0c0;
  color: #4c4c4e;
}

.digital_detail .block_chain_addr{
  font-size: 20px;
  margin: 10px 0px;
  margin-left: 20px;
}
.digital_detail .block_chain_addr span{
  font-weight: bold;
  font-size: 25px;
}

.digital_detail .author_story .story_title{
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  margin: 30px 0px;
}
.digital_detail .author_story .story_content{
  font-size: 24px;
  padding: 0px 40px;
  line-height: 45px;
  overflow: auto;
  height: 400px;
}
</style>
