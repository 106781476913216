<template>
  <div class="payment_track">
      <div class="header">
        <md-icon class="back_btn" v-on:click="back_btn()" name="arrow-left" size="md"></md-icon>
        <div class="title">我的扶贫款项追踪</div>
      </div>
      <div class="track_list">
        <div class="track_item" v-for="item in payment_list">
          <div class="row_1">{{item.title}}<span class="price">&yen;&nbsp;<md-amount :value='item.price'></md-amount></span></div>
          <div class="row_2">款项流程</div>
          <md-steps
            direction="vertical"
            :steps="track_list"
            :current="item.track_num"
            vertical-adaptive
          >
          </md-steps>
        </div>
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {Button, Icon, Amount, Steps} from 'mand-mobile'



export default {
  name: "PaymentTrack",
  components: {
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Amount.name]: Amount,
    [Steps.name]: Steps,
  },
  data(){
    return {
      payment_list: [
        {'id': 1, 'title':'冰雪旅游', 'price': 99, 'track_num': 0},
        {'id': 3, 'title':'稻田舞者', 'price': 18, 'track_num': 4},
        {'id': 4, 'title':'脱贫致富', 'price': 59, 'track_num': 5},
      ],
      track_list: [
        {'name': '我的钱款'},
        {'name': '中国移动通信集团'},
        {'name': '吉林省财政专项扶贫账户'},
        {'name': '地市财政专项扶贫账户'},
        {'name': '乡镇及县直财政扶贫账户'},
        {'name': '贫困户银行账户'},
      ]
    }
  },
  mounted() {
  },
  methods:{
    back_btn(){
      this.$router.back()
    }
  }
};
</script>
<style>

  .payment_track .header{
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 1002;
    height: 100px;
    background-color: rgba(255, 255, 255, 1);
  }
  .payment_track .header .back_btn{
    margin-left: 30px;
    position: absolute;
    left: 20px;
    top: 40px;
  }

  .payment_track .header .title{
    font-size: 36px;
    font-weight: bold;
    margin-top: 30px;
  }

  .payment_track .track_list{
    padding: 40px;
    padding-top: 80px;
  }

  .payment_track .track_list .track_item{
    background-color: hsl(200, 13%, 95%);
    margin: 30px 0px;
    border-radius: 30px;
    font-size: 32px;
    padding: 20px 40px;
    text-align: left;
  }

  .payment_track .track_list .track_item .row_1{
    border-bottom: 1px solid #999;
    padding: 10px;
    padding-bottom: 10px;
  }
  .payment_track .track_list .track_item .row_1 .price{
    margin-left: 30px;
    display: inline-block;
    float: right;

  }

  .payment_track .track_list .track_item .row_2{
    margin-top: 40px;
    font-size: 26px;
    padding-left: 20px;
  }
  .payment_track .track_list .track_item .md-steps{
    height: 500px;
  }
</style>
