<template>
  <div class="home">
    <Header></Header>
    <Banner></Banner>

    <div class="tab">
      <md-tabs @change="check_tab">
        <md-tab-pane class="content" name="p1" label="数字藏品">
          <DigitalCollection></DigitalCollection>
        </md-tab-pane>
        <md-tab-pane class="content fail_content" style="padding-top:0px;" name="p2" label="发售日历">
          <SalesPlan></SalesPlan>
        </md-tab-pane>
        <md-tab-pane class="content fail_content" name="p3" label="内容精选">
          <SpecialTopic></SpecialTopic>
        </md-tab-pane>
      </md-tabs>
    </div>
    <Slogan></Slogan>
    <FooterBar></FooterBar>

    <md-popup v-model="show_order_confirm" position="bottom">
      <OrderConfirm ref="order_confirm"></OrderConfirm>
    </md-popup>

    <md-popup v-model="show_digital_detail" position="bottom">
      <DigitalDetail ref="digital_detail"></DigitalDetail>
    </md-popup>

  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/home/Header.vue";
import Banner from "@/components/home/Banner.vue";
import OrderConfirm from "@/components/home/OrderConfirm.vue";
import DigitalDetail from "@/components/home/DigitalDetail.vue";

import DigitalCollection from "@/components/home/DigitalCollection.vue";
import SalesPlan from "@/components/home/SalesPlan.vue";
import SpecialTopic from "@/components/home/SpecialTopic.vue";

import Slogan from "@/components/home/Slogan.vue";
import FooterBar from "@/components/FooterBar.vue";

import { Popup, Tag, Tabs, TabPane, Button, Field, RadioList, Amount, Toast } from 'mand-mobile'

import $ from 'jquery'

export default {
  name: "home",
  components: {
    Header,
    Banner,
    DigitalCollection,
    SalesPlan,
    SpecialTopic,
    OrderConfirm,
    DigitalDetail,
    Slogan,
    FooterBar,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Popup.name]: Popup,
    [Field.name]: Field,
    [RadioList.name]: RadioList,
    [Amount.name]: Amount,
    [Button.name]: Button,
    [Tag.name]: Tag
  },
  data() {
    return {
      show_order_confirm: false,
      show_digital_detail: false,
      tabOffsetTop: 0,
      scrollTop: 0,
      order_info: {},
      myBank: "0",
      customer_info: {
        'customer_address': '吉林移动生产中心A66号',
        'customer_name': '程宇鑫',
        'customer_phone': '137******14'
      },
    }
  },
  mounted: function () {
    let tabEle = document.getElementsByClassName('md-tab-bar-list')[0]
    console.log(tabEle)
    this.tabOffsetTop = tabEle.offsetTop
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed: function () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      var offsetTop = $('.tab')[0].offsetTop;
      //进行比较设置位置fixed
      var whether = scrollTop + 140 > offsetTop;

      if (whether) {
        if (!$('.tab .md-tab-bar').hasClass('isFixed')) {
          $('.tab .md-tab-bar').addClass('isFixed')
        }
      } else {
        if ($('.tab .md-tab-bar').hasClass('isFixed')) {
          $('.tab .md-tab-bar').removeClass('isFixed')
        }
      }
    },
    check_tab() {
      window.scrollTo({
        top: $('.tab')[0].offsetTop - 100,//需要父元素设置postion(relative、absolute、fixed)
        behavior: "smooth"
      })
    },
    order_confirm(order_info) {
      this.$refs.order_confirm.set_order_info(order_info)
      this.$refs.order_confirm.set_customer_info(this.customer_info)
      this.$refs.order_confirm.set_my_bank(this.myBank)

      this.show_order_confirm = true
    },
    commit_order() {
      this.show_order_confirm = false
      Toast.succeed('订单提交成功')
    },
    digital_detail(detail){
      this.show_digital_detail = true
    }
  },
};
</script>
<style>
.isFixed {
  position: fixed;
  top: 100px;
  z-index: 1000;
}

.tab {
  margin: 20px;
}

.tab .content {}

.home {
  padding-bottom: 100px;
}

.tab .md-tab-bar-list {}
</style>
