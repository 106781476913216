import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import My from '../views/My.vue'
import MyOrder from '../views/MyOrder.vue'
import TopicDetail from '../views/TopicDetail.vue'
import PaymentTrack from '../views/my/PaymentTrack.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{
      keepAlive:true
    }
  },
  {
    path: '/my',
    name: 'My',
    component: My
  },
  {
    path: '/my/order',
    name: 'MyOrder',
    component: MyOrder
  },
  {
    path: '/topic_detail',
    name: 'TopicDetail',
    component: TopicDetail
  },
  {
    path: '/my/payment_track',
    name: 'PaymentTrack',
    component: PaymentTrack
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
