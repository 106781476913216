<template>
    <div class="sys_menu">
      <md-field class="menu">
        <md-cell-item class="menu_item" :title="item.label" arrow v-for='item in items' :key='item.name' @click='menu_click(item.url)'>
          <md-icon :name="item.icon" slot="left" size="lg"></md-icon>
        </md-cell-item>
      </md-field>

      <md-field class="menu">
        <md-cell-item class="menu_item" title="设置" arrow>
          <md-icon name="setting" slot="left" size="lg"></md-icon>
        </md-cell-item>
        <md-cell-item class="menu_item" title="退出登录" v-on:click="logout_btn">

          <font-awesome-icon slot="left" icon="sign-out" />
        </md-cell-item>
      </md-field>

      <md-dialog
        title=""
        :closable="false"
        v-model="logout.open"
        :btns="logout.btns"
      >
        <div class="logout">确认要退出登录吗</div>
      </md-dialog>
    </div>
  </template>

  <script>
  import {Field, CellItem, Icon, Dialog, Toast} from 'mand-mobile'
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


  export default {
    name: "SysMenu",
    components: {
        [Field.name]: Field,
        [CellItem.name]: CellItem,
        [Icon.name]: Icon,
        [Dialog.name]: Dialog,
        'font-awesome-icon': FontAwesomeIcon
    },
    props: {
    },
    data(){
      return {
        items: [
          {name: 1, label: '我的盲盒', icon: 'balance', url: '#'},
          {name: 2, label: '我的扶贫款项追踪', icon: 'address-book', url: '/my/payment_track'},
          {name: 3, label: '我的客服', icon: 'service', url: '#'},
          {name: 4, label: '关于链+', icon: 'info-solid', url: '#'},
        ],
        logout: {
          open: false,
          btns: [
            {
              text: '取消',
            },
            {
              text: '退出登录',
              warning: true,
              handler: this.onLogoutConfirm,
            },
          ],
        },
      }
    },
    methods: {
      logout_btn() {
        this.logout.open = true
      },
      menu_click(url){
        if(url !== '#'){
          this.$router.push(url)
        }
      },
      onLogoutConfirm(){
        window.localStorage.removeItem('user_mobile')
        this.$router.go(0)
      }
    }
  };
  </script>

  <style scoped>
    .sys_menu{
      text-align: left;
    }
    .sys_menu .menu{
      background-color: hsl(200, 13%, 95%);
      margin: 40px;
      margin-top: 20px;
      margin-bottom: 20px;
      border-radius: 20px;
      padding: 0px 30px;
    }
    .sys_menu .menu_item{
      font-size: 30px;
    }
    .logout{
      text-align: center;
      font-size: 32px;
    }

  </style>
