<template>
    <div class="info">


      <div class="user_info" v-if="login_flag">
        <img class="avatar" :src="user_info.avatar"/>
        <div class="base_info">
          <div class="user_nick">{{user_info.nickname}}</div>
          <div class="user_mobile">{{user_info.mobile.replace(/(\d{3})(\d{6})(\d{2})/,"$1******$3")}}</div>
          <md-button type="default" class="my_zone" round size="small">个人主页</md-button>
        </div>
        <div class="hash_addr">
          区块链地址：a3590023df66ac92ae3e3...
          <md-button type="link">复制</md-button>
        </div>
      </div>

      <div class="user_info" v-else  v-on:click="show_login=true">
        <img class="avatar" :src="user_info.avatar"/>
        <div class="base_info">
          <div class="user_nick">未登录</div>
          <div class="user_mobile">登录后查看你的数字藏品</div>
        </div>
      </div>

      <md-landscape class="login" v-model="show_login" full-screen>
        <Login></Login>
      </md-landscape>
    </div>
  </template>

  <script>
  import Login from "@/components/my/Login.vue";
  import {Icon, Button, Landscape} from 'mand-mobile'

  export default {
    name: "UserInfo",
    components: {
      Login,
      [Icon.name]: Icon,
      [Button.name]: Button,
      [Landscape.name]: Landscape,
    },
    props: {
    },
    data(){
      return {
        'login_flag': false,
        'show_login': false,
        'user_info':{
          'nickname': '爱上郎静山',
          'mobile': '158******00',
          'avatar': '/static/images/user_avatar.png'
        }
      }
    },
    mounted(){
      this.update_login_status()
    },
    methods: {
      login() {
        this.show_login = true
      },
      close_login(user_mobile){
        this.show_login = false
        this.login_flag = true
        this.user_info.mobile = user_mobile
        window.localStorage.setItem('user_mobile', user_mobile)
      },
      update_login_status(){
        let user_mobile = window.localStorage.getItem('user_mobile')

        if(user_mobile){
          this.login_flag = true
          this.user_info.mobile = user_mobile
        }
      }
    }
  };
  </script>

  <style scoped>
    .title, .message{
      display: inline-block;
      font-size: 35px;
      font-weight: bold;
    }
    .title{
      float: left;
    }

    .info{
      padding: 30px;
      margin: 40px;
      margin-top: 80px;
      margin-bottom: 0px;
    }
    .user_info{
      text-align: left;
    }
    .user_info .avatar{
      width:120px;
      height: 120px;
      border-radius: 50%;
      display: inline-block;
    }
    .user_info .base_info{
      font-size: 32px;
      display: inline-block;
      position: absolute;
      padding: 20px;
      font-weight: bold;
      width: 480px;
    }
    .user_info .base_info .user_mobile{
      color: #666f83;
      font-weight: unset;
      font-size: 24px;
      margin-top: 10px;
    }
    .user_info .base_info .my_zone{
      width: 160px;
      position: absolute;
      top: 20px;
      right: 0px;
      font-size: 24px;
    }

    .login .md-popup-box{
      z-index: 10001 !important;
    }
    .user_info .hash_addr{
      border-top: 1px solid #666f83;
      padding-top: 20px;
      font-size: 26px;
    }
  </style>
