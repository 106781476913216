<template>
  <div class="special_topic">
    <div class="topic_item" v-for="item in item_data" v-on:click="to_detail(item)">
      <img :src="item.thumb" class="thumb" />
      <div class="title">{{item.title}}</div>
      <div class="desc">{{item.desc}}</div>
    </div>
  </div>
</template>

<script>
import { Icon, Tag, Button, Steps, Amount } from 'mand-mobile'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: "SalesPlan",
  components: {
    [Icon.name]: Icon,
    [Tag.name]: Tag,
    [Button.name]: Button,
    [Steps.name]: Steps,
    [Amount.name]: Amount,
    'font-awesome-icon': FontAwesomeIcon
  },
  data() {
    return {
      item_data: [
        {
          'id': 1,
          'thumb': '/static/images/topic_thumb_cc.jpeg',
          'title': '东北亚区域性中心城市',
          'desc': '长春市位于北 纬43°05′～45°15′、东经124°18′～127°05′，居北半球中纬度北温带,地处中华人民共和国东北地区中部、京哈与珲乌2条交通线交会处,是吉林省的政治、经济、文化中心。长春市西北与松原市毗邻,西南和四平市相连,东南与吉林市相依,东北同黑龙江省接壤。据全国第二次土地调查成果显示,全市总面积24744平方公里。辖4县(市)7区,包括公主岭市、榆树市、农安县、德惠市和朝阳区、南关区、宽城区、二道区、绿园区、双阳区、九台区。',
          'digital_item': [
            {'id': 1, 'title': '丰收的喜悦', 'thumb': '/static/images/digital.jpg', 'sale_date': '09.26 12:00 开售'},
            {'id': 1, 'title': '查干湖冬捕', 'thumb': '/static/images/digital.jpg', 'sale_date': '09.25 12:00 开售'},
            {'id': 1, 'title': '丰收的喜悦', 'thumb': '/static/images/digital.jpg', 'sale_date': '09.24 12:00 开售'},
            {'id': 1, 'title': '丰收的喜悦', 'thumb': '/static/images/digital.jpg', 'sale_date': '09.23 12:00 开售'},
          ]
        },
        {
          'id': 2,
          'thumb': '/static/images/topic_thumb_yb.jpeg',
          'title': '尊老爱幼,注重礼仪,崇文重教',
          'desc': '延边朝鲜族自治州位于吉林省东部，幅员4.33万平方公里，约占吉林省的四分之一。总人口207.2万人，其中朝鲜族人口74.2万人，占全州总人口的35.8%，是我国唯一的朝鲜族自治州和最大的朝鲜族聚居地区。自治州成立于1952年9月3日，下辖延吉、图们、敦化、珲春、龙井、和龙6市和汪清、安图2县，首府为延吉市。延边民风淳厚，朝鲜族能歌善舞，尊老爱幼，注重礼仪，崇文重教，各族群众热情好客，素有“礼仪之乡”、“歌舞之乡”、“教育之乡”、“足球之乡”的美誉。',
          'digital_item': [
            {'id': 1, 'title': '丰收的喜悦', 'thumb': '/static/images/digital.jpg', 'sale_date': '09.26 12:00 开售'},
            {'id': 1, 'title': '查干湖冬捕', 'thumb': '/static/images/digital.jpg', 'sale_date': '09.25 12:00 开售'},
            {'id': 1, 'title': '丰收的喜悦', 'thumb': '/static/images/digital.jpg', 'sale_date': '09.24 12:00 开售'},
            {'id': 1, 'title': '丰收的喜悦', 'thumb': '/static/images/digital.jpg', 'sale_date': '09.23 12:00 开售'},
          ]
        },
        {
          'id': 3,
          'thumb': '/static/images/topic_thumb_sy.jpeg',
          'title': '辽金文化、满蒙文化、草原文化',
          'desc': '早在新石器时代，这片土地上就有人类繁衍生息。2000多年前的古夫余国、1000多年前的渤海国等曾在这里开疆拓土。远古猛犸象化石、辽帝春捺钵遗址群、青山头遗址、大金得胜陀颂碑、塔虎城、王爷府、孝庄祖陵等诸多历史遗迹，记录着松原悠久的历史。辽金文化、满蒙文化、草原文化、渔猎文化、农耕文化、佛教文化、石油文化的交融共生，形成了松原独有的文化特质。蒙古族乌力格尔、马头琴音乐、查干湖冬捕习俗等10项文化遗产被列入国家级非物质文化遗产名录。《甲午风云》《圣水湖畔》《美丽的田野》《大金始祖》等一批经典影视作品在这里拍摄，《铁血女真》《洪皓》《大布苏》等一批特色民族戏剧荣获多项国家级大奖。这里也是“中国马头琴之乡”，“2019把马头琴表演”入选吉尼斯世界纪录。',
          'digital_item': [
            {'id': 1, 'title': '丰收的喜悦', 'thumb': '/static/images/digital.jpg', 'sale_date': '09.26 12:00 开售'},
            {'id': 1, 'title': '查干湖冬捕', 'thumb': '/static/images/digital.jpg', 'sale_date': '09.25 12:00 开售'},
            {'id': 1, 'title': '丰收的喜悦', 'thumb': '/static/images/digital.jpg', 'sale_date': '09.24 12:00 开售'},
            {'id': 1, 'title': '丰收的喜悦', 'thumb': '/static/images/digital.jpg', 'sale_date': '09.23 12:00 开售'},
          ]
        },
        {
          'id': 4,
          'thumb': '/static/images/topic_thumb_sp.jpeg',
          'title': '“四战四平”闻名中外，被誉为“东方马德里”',
          'desc': '四平地处东北亚区域中心地带，位于东北振兴哈大发展轴上，是哈长城市群重要战略支点市，吉林省向南开放的桥头堡。全市幅员1.03万平方公里，人口181.4万，其中市区面积1100平方公里，市区建成区面积62.5平方公里。辖梨树、双辽、伊通三个县（市），铁东、铁西两个区，1个国家级经济技术开发区，5个省级经济开发区，1个工业集中区。',
          'digital_item': [
            {'id': 1, 'title': '丰收的喜悦', 'thumb': '/static/images/digital.jpg', 'sale_date': '09.26 12:00 开售'},
            {'id': 1, 'title': '查干湖冬捕', 'thumb': '/static/images/digital.jpg', 'sale_date': '09.25 12:00 开售'},
            {'id': 1, 'title': '丰收的喜悦', 'thumb': '/static/images/digital.jpg', 'sale_date': '09.24 12:00 开售'},
            {'id': 1, 'title': '丰收的喜悦', 'thumb': '/static/images/digital.jpg', 'sale_date': '09.23 12:00 开售'},
          ]
        }
      ],
    }
  },
  methods: {
    to_detail(detail) {
      this.$router.push({
        'path': '/topic_detail',
        query: {
          data: JSON.stringify(detail)
        }
      })
    }
  }
};
</script>

<style scoped>
.special_topic {
  padding: 0px 40px;
  text-align: left;
}

.topic_item{
  border-radius: 30px;
  background-color: hsl(200, 13%, 95%);
  padding-bottom: 20px;
  margin-bottom: 30px;
}
.topic_item .thumb{
  border-radius: 30px;
  width: 100%;
  height: 100%;
}
.topic_item .title{
  font-size: 32px;
  font-weight: bold;
  padding-left: 20px;
}
.topic_item .desc{
  padding-top: 10px;
  font-size: 24px;
  line-height: 35px;
  height: 70px;
  margin: 0px 20px;

  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
  -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
  -webkit-line-clamp: 2; /** 显示的行数 **/
  overflow: hidden;  /** 隐藏超出的内容 **/
}
</style>
