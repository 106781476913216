<template>
  <div class="order">
      <div class="header">
        <md-icon class="back_btn" v-on:click="back_btn()" name="arrow-left" size="md"></md-icon>
        <div class="title">我的订单</div>
      </div>
      <div class="order_list">
        <div class="order_item" v-for="item in order_list">
          <div class="row_1">
            {{item.order_num}}
            <md-tag v-if="item.order_status==1" size="large" font-weight="bold" shape="square" font-color="#666f83" type="ghost">已完成</md-tag>
            <md-tag v-if="item.order_status==2" size="large" font-weight="bold" shape="square" font-color="#666f83" type="ghost">待付款</md-tag>
            <md-tag v-if="item.order_status==3" size="large" font-weight="bold" shape="square" font-color="#666f83" type="ghost">待发货</md-tag>
            <md-tag v-if="item.order_status==4" size="large" font-weight="bold" shape="square" font-color="#666f83" type="ghost">待收货</md-tag>
            <md-tag v-if="item.order_status==5" size="large" font-weight="bold" shape="square" font-color="#666f83" type="ghost">待评价</md-tag>
          </div>

          <table class="digital_info">
            <colgroup>
              <col width="30%">
              <col width="70%">
            </colgroup>
            <tr>
              <td rowspan="4" style="text-align:center"><img :src="item.thumb" class="thumb"/></td>
            </tr>
            <tr>
              <td class="title">
                &emsp;{{item.title}}

              </td>
            </tr>
            <tr>
              <td class="author_type">&emsp;
                <md-tag
                  size="large"
                  shape="fillet"
                  type="ghost"
                  fill-color="rgba(255, 91, 96, .1)"
                  font-weight="normal"
                  font-color="#FF5B60"
                  >{{item.author_type}}</md-tag>
              </td>
            </tr>
            <tr>
              <td class="price">&emsp;
                &yen;&nbsp;<md-amount :value="item.price"></md-amount>
              </td>
            </tr>
          </table>
        </div>
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {Button, Icon, Amount, Tag} from 'mand-mobile'



export default {
  name: "MyOrder",
  components: {
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Amount.name]: Amount,
    [Tag.name]: Tag,
  },
  data(){
    return {
      order_list: [
        {'id': 1, 'order_num': '20220930132528001', 'order_status': 1, 'title':'冰雪旅游', 'thumb': '/static/images/bxly1b1.jpg', 'author_type': '企业', 'price': 99},
        {'id': 3, 'order_num': '20220927111728003', 'order_status': 1, 'title':'稻田舞者', 'thumb': '/static/images/dtwz1b1.jpg', 'author_type': '企业', 'price': 18},
        {'id': 4, 'order_num': '20220925092328004', 'order_status': 1, 'title':'脱贫致富', 'thumb': '/static/images/tpzfxky1b1.jpg', 'author_type': '企业', 'price': 59},
        {'id': 2, 'order_num': '20220915203528002', 'order_status': 2, 'title':'霜叶火红', 'thumb': '/static/images/syhh.jpeg', 'author_type': '企业','price': 59},
      ]
    }
  },
  mounted() {
  },
  methods:{
    back_btn(){
      this.$router.back()
    }
  }
};
</script>
<style>

  .order .header{
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 1002;
    height: 100px;
    background-color: rgba(255, 255, 255, 1);
  }
  .order .header .back_btn{
    margin-left: 30px;
    position: absolute;
    left: 20px;
    top: 40px;
  }

  .order .header .title{
    font-size: 36px;
    font-weight: bold;
    margin-top: 30px;
  }

  .order .order_list{
    padding: 40px;
    padding-top: 80px;
  }

  .order .order_list .order_item{
    background-color: hsl(200, 13%, 95%);
    margin: 30px 0px;
    border-radius: 20px;
    font-size: 32px;
    padding: 20px 40px;
    text-align: left;
  }

  .order .order_list .order_item .row_1{
    padding: 10px;
    padding-bottom: 10px;
    font-weight: bold;
  }
  .order .order_list .order_item .row_1 .md-tag{
    display: inline-block;
    float: right;
    border: none;
  }
  .order .order_list .order_item .row_1 .md-tag .size-large{
    font-size: 32px !important;
  }
  .order .order_list .order_item .row_1 .md-tag .type-ghost{
    border: none;
  }

  .order .order_list .order_item .digital_info{
    width: 100%;
    text-align: left;
    margin-top: 30px;
  }

  .order .order_list .order_item .digital_info .thumb{
    width: 180px;
    height: 180px;
    border-radius: 20px;
  }
  .order .order_list .order_item .digital_info .title{
    font-size: 32px;
    text-align: left;
    padding: 0px;
    border-bottom: none;
  }
  .order .order_list .order_item .digital_info .price{
    font-size: 40px;
    text-align: right;
  }

</style>
