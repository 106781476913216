<template>
  <div class="digital">
    <div class="item" :key="$index" v-for="(item, $index) in item_data">
      <img class="thumb" :src="item.thumb" v-on:click='to_digital_detail(item.id)' />
      <div class="item_tag">
        <md-icon name='time' class="item_icon"></md-icon>&nbsp;{{item.sale_date}}
      </div>
      <div class="item_tag2">
        {{item.item_type_zh}}
      </div>
      <div class="item_title">
        {{item.title}}&nbsp;
        <md-tag size="large" shape="fillet" type="ghost" fill-color="rgba(255, 91, 96, .1)" font-weight="normal"
          font-color="#FF5B60">{{item.author_type}}</md-tag>
        <md-button class="like_btn" size="small" type="primary" inline plain>+ 收藏</md-button>
      </div>
      <div class="item_quantity">
        <span>限量</span>
        <span>{{item.quantity}}份</span>
      </div>
      <div class="item_price">
        &yen;&nbsp;<md-amount :value="item.price" has-separator></md-amount>
        <md-button class="buy_btn" type="primary" size="small" inline v-on:click="buy_digital(item)">立即购买</md-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon, Tag, Button, Amount } from 'mand-mobile'


export default {
  name: "DigitalCollection",
  components: {
    [Icon.name]: Icon,
    [Tag.name]: Tag,
    [Button.name]: Button,
    [Amount.name]: Amount
  },
  data() {
    return {
      item_data: [
        { 'id': 2, 'title': '查干湖冬捕', 'desc': '《查干湖冬捕》习近平总书记到松原查干湖调研时强调，“一定要守住查干湖这块金字招牌”', 'thumb': '/static/images/cghdbb1.jpeg', 'author_type': '企业', 'item_type': 2, 'item_type_zh': '农产品盲盒', 'sale_date': '热销中  剩余数量：354', 'quantity': 500, 'price': 18.00 },
        { 'id': 1, 'title': '银松', 'desc': '在银装素裹的松花江畔，洁白晶莹的霜花缀满了枝头,在阳光照耀下,银光闪烁', 'thumb': '/static/images/ys1b1.jpeg', 'author_type': '个人', 'item_type': 1, 'item_type_zh': '权益盲盒', 'sale_date': '敬请期待 09.26 12:00 开售', 'quantity': 1000, 'price': 59 },
        { 'id': 3, 'title': '丰收的喜悦', 'desc': '丰收在望，这是对生活在这片土地上辛勤劳作的农民朋友们最好的回馈', 'thumb': '/static/images/fsdxy1b1.jpg', 'author_type': '个人', 'item_type': 1, 'item_type_zh': '权益盲盒', 'sale_date': '敬请期待 09.24 12:00 开售', 'quantity': 100, 'price': 99.00 },
        { 'id': 4, 'title': '稻田舞者', 'desc': '在通化市柳河县孤山子镇高台村，农忙时节农民大姐顶着烈日在插秧苗，在远处青山与水波倒影间，仿佛一个舞者舞动在舞台之上', 'thumb': '/static/images/dtwz1b1.jpg', 'author_type': '企业', 'item_type': 1, 'item_type_zh': '权益盲盒', 'sale_date': '热销中 剩余数量：883', 'quantity': '1000', 'price': 18.00 },
        { 'id': 5, 'title': '冰雪旅游', 'desc': '在临江市松岭雪村，冬日大雪后村民拉牛爬犁出行', 'thumb': '/static/images/bxly1b1.jpg', 'author_type': '企业', 'item_type': 1, 'item_type_zh': '权益盲盒', 'sale_date': '热销中 剩余数量：124', 'quantity': '200', 'price': 99.00 },
        { 'id': 6, 'title': '脱贫致富', 'desc': '吉林省梨树县双河乡三合堡村建立食用菌养殖基地，村民在家门口就能打工赚钱，年底有分红', 'thumb': '/static/images/tpzfxky1b1.jpg', 'author_type': '企业', 'item_type': 1, 'item_type_zh': '权益盲盒', 'sale_date': '热销中 剩余数量：327', 'quantity': '500', 'price': 59.00 },
        { 'id': 7, 'title': '牧归', 'desc': '在白城市洮北区发家村，两位村民正赶着羊群放牧，喜悦之情溢于言表', 'thumb': '/static/images/mg1b1.jpg', 'author_type': '企业', 'item_type': 1, 'item_type_zh': '权益盲盒', 'sale_date': '敬请期待 09.23 12:00 开售', 'quantity': '1000', 'price': 59.00 },
      ],
    }
  },
  methods: {
    buy_digital(item) {
      this.$parent.$parent.$parent.order_confirm(item)
    },
    to_digital_detail(id) {
      this.$parent.$parent.$parent.digital_detail(id)
    }
  }
};
</script>

<style scoped>
.digital {
  padding: 40px;
}

.digital .item:first-child {
  margin-top: 0px;
}

.digital .item {
  border-radius: 30px;
  margin-top: 40px;
  position: relative;
  background-color: hsl(200, 13%, 95%);
  padding-bottom: 20px;
}

.digital .item .thumb {
  width: 100%;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  position: initial;
  left: 0;
}

.digital .item .item_icon {
  margin-top: 10px;
  position: absolute;
  left: 15px;
}

.digital .item .item_tag {
  background: #000;
  position: absolute;
  color: #bfd7af;
  top: 40px;
  left: 20px;

  text-align: left;
  height: 50px;
  line-height: 55px;
  font-size: 24px;
  border-radius: 25px;
  padding-left: 50px;
  font-weight: bold;
  padding-right: 20px;
}

.digital .item .item_tag2 {
  background: #000;
  color: #bfd7af;
  position: absolute;
  top: 40px;
  right: 20px;
  height: 50px;
  padding: 0px 20px;
  line-height: 55px;
  font-size: 24px;
  border-radius: 25px;
  font-weight: bold;
}

.digital .item .item_title {
  text-align: left;
  font-size: 32px;
  font-weight: bold;
  padding: 5px 20px;
}

.digital .item .item_quantity {
  font-size: 26px;
  width: 200px;
  padding: 10px;
  text-align: left;
  margin-left: 10px;
  ;
}

.digital .item .item_quantity span {
  padding: 10px;
}

.digital .item .item_quantity span:last-child {
  display: inline-block;
  background-color: #4c4c4e;
  color: #eee0c0;
}

.digital .item .item_quantity span:first-child {
  display: inline-block;
  background: #eee0c0;
  color: #4c4c4e;
}

.digital .item .like_btn {
  float: right;
}

.digital .item .item_price {
  font-size: 40px;
  font-weight: bold;
  text-align: left;
  padding: 20px;
}

.digital .item .item_price .buy_btn {
  float: right;
  margin-top: -10px;
}
</style>
