<template>
    <div class="user_menu">
      <div class="menu">
        <div class="custom-item" v-for="item in items" v-on:click="route_click(item)">
          <div class="icon">
              <md-icon :name="item.icon" size='lg'/>
          </div>
          <div class="text">
              <span v-text="item.label"></span>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import {TabBar, Icon} from 'mand-mobile'


  export default {
    name: "UserMenu",
    components: {
      [TabBar.name]: TabBar,
      [Icon.name]: Icon,
    },
    props: {
    },
    data(){
      return {
        items: [
          {name: 1, label: '我的喜欢', icon: 'profession', url: '#'},
          {name: 2, label: '我的藏品', icon: 'card-bag', url: '#'},
          {name: 3, label: '我的订单', icon: 'order', url: '/my/order'},
        ],
      }
    },
    methods: {
      route_click(e){
        console.log(e)
        if(e.url !== '#'){
          this.$router.push(e.url)
        }
      },
    }
  };
  </script>

  <style scoped>
    .user_menu{

    }
    .user_menu .menu{
      background-color: hsl(200, 13%, 95%);
      margin: 40px;
      margin-top: 10px;
      margin-bottom: 0px;
      border-radius: 20px;
    }
    .user_menu .menu .custom-item{
      font-size: 30px;
      display: inline-block;
      padding: 30px 15px ;
      width: 27%;
    }

    .user_menu .menu .custom-item .text{
      margin-top: 20px;
    }


  </style>
