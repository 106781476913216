<template>
  <div class="slogan">
    <div class="title">链+，链接千万家，惠及你我他</div>
  </div>
</template>

<script>
import {Icon} from 'mand-mobile'

export default {
  name: "Slogan",
  components: {
    [Icon.name]: Icon
  },
  props: {
  },
  methods: {
    greet() {
      Toast.info("I'm Batman!");
    }
  }
};
</script>

<style scoped>
  .slogan .title{
    font-size: 24px;
    margin-bottom: 40px;
  }
</style>
