<template>
    <div class="login">
      <div class="step1" v-if='step1_show'>
        <div class="title">登录数藏<br>发现有趣</div>
        <md-input-item
          class="user_mobile"
          v-model="user_mobile"
          type="phone"
          placeholder="请输入手机号码"
          brief="未注册的手机号通过验证将自动注册。"
          :maxlength="11"
          @change="input_mobile"
        ></md-input-item>
        <md-agree
        class="agree"
        v-model="agreeConf.checked"
        :size="agreeConf.size"
        @change="check_send_btn"
        >
          已阅读并同意 <a>《用户服务协议》</a> <a>《隐私权政策》</a>
        </md-agree>
        <md-button v-on:click="send_message" :inactive="send_message_btn" class="send_message_btn" type="primary" round>发送短信验证码</md-button>
      </div>

      <div class="step2" v-if="step2_show">
        <div class="title">请输入短信验证码</div>
        <div class="sub_title">短信验证码已发送至{{user_mobile.replace(/(\d{3})(\d{6})(\d{2})/,"$1******$3")}}，请在下方输入6位数字验证码</div>
        <md-codebox
          class="code_box"
          v-model="sms_code"
          :maxlength="6"
          @submit="submit_code"
          justify
          system
        />
        <div class="resend_btn">
          <md-button type="link">重新发送</md-button>
        </div>

      </div>
    </div>
  </template>

  <script>
  import {InputItem, Agree, Button, Codebox, NumberKeyboard} from 'mand-mobile'


  export default {
    name: "Login",
    components: {
      [InputItem.name]: InputItem,
      [Agree.name]: Agree,
      [Button.name]: Button,
      [Codebox.name]: Codebox,
      [NumberKeyboard.name]: NumberKeyboard,
    },
    props: {
    },
    data(){
      return {
        send_message_btn: true,
        step1_show: true,
        step2_show: false,
        agreeConf: {
          checked: false,
          name: 'agree0',
          size: 'md',
          disabled: false,
          introduction: '选中状态',
        },
        user_mobile: '',
        sms_code: ""
      }
    },
    methods: {
      input_mobile() {
        this.check_send_btn()
      },
      check_send_btn(){
        if(this.agreeConf.checked && /^1[3-9]\d{9}$/.test(this.user_mobile)){
          this.send_message_btn = false
        }else{
          this.send_message_btn = true
        }
      },
      send_message(){
        this.step1_show = false;
        this.step2_show = true;
      },
      submit_code(code){
        this.$parent.$parent.$parent.close_login(this.user_mobile)
        this.sms_code = ''
        this.user_mobile = ''
        this.step1_show = true
        this.step2_show = false
        this.send_message_btn = true
        this.agreeConf.checked = false

      }
    }
  };
  </script>

  <style scoped>
    .login{
        text-align: left;
        padding: 60px;
    }
    .login .title{
        margin-top: 70px;
        font-size: 48px;
        font-weight: bold;
        line-height: 60px;
    }
    .login .user_mobile{
        margin-top: 80px;
    }
    .login .agree{
      margin-top: 120px;
      font-size: 25px;
    }
    .login a{
      color: #00af3b;
    }
    .login .send_message_btn{
      margin-top: 20px;
    }
    .login .sub_title{
      margin-top: 50px;
      font-size: 26px;
    }
    .login .code_box{
      margin-top: 80px;
    }
    .login .resend_btn{
      text-align: center;
      margin-top: 100px;
    }
  </style>
