<template>
    <div class="header">
      <div class="title">链+ <span>链接千万家，惠及你我他</span></div>
      <div class="message">
        <md-icon name='message' size="lg"></md-icon>
      </div>
    </div>
  </template>

  <script>
  import {Icon} from 'mand-mobile'

  export default {
    name: "Header",
    components: {
      [Icon.name]: Icon
    },
    props: {
    },
    methods: {
      greet() {
        Toast.info("I'm Batman!");
      }
    }
  };
  </script>

  <style scoped>
    .title, .message{
      display: inline-block;
      font-size: 35px;
      font-weight: bold;
    }
    .title{
      float: left;
    }
    .header .title{
      font-size: 40px;
      font-weight: bold;
    }
    .header .title span{
      font-size: 24px;
      /* color: #666f83; */
      display: inline-block;
      margin-left: 10px;;
    }

    .header{
      padding: 30px;
      position: fixed;
      top: 0px;
      left: 0px;
      right: 0px;
      z-index: 1000;
      background-color: rgba(255, 255, 255, 1);
    }

    .message{
      float: right;
    }
  </style>
