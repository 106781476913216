<template>
    <div class="footer">
        <md-tab-bar
          v-model="current"
          :items="items"
          :has-ink="false"
          @change="route_click"
          >
          <template slot="item" slot-scope="{ item }">
              <div class="custom-item">
              <div class="icon">
                  <md-icon :name="item.icon" />
              </div>
              <div class="text">
                  <span v-text="item.label"></span>
              </div>
              </div>
          </template>
        </md-tab-bar>
    </div>
  </template>

  <script>
  import {TabBar, Icon} from 'mand-mobile'

  export default {
    name: "FooterBar",
    components: {
        [TabBar.name]: TabBar,
        [Icon.name]: Icon,
    },
    data() {
      return {
        current: 1,
        items: [
          {name: 1, label: '首页', icon: 'home', url: '/'},
          {name: 2, label: '发现', icon: 'discovery', url: '#'},
          {name: 3, label: '藏品', icon: 'card-bag', url: '#'},
          {name: 4, label: '我的', icon: 'user', url: '/my'}
        ],
      }
    },
    methods: {
      route_click(e){
        if(e.url != this.$route.path && e.url !== '#'){
          this.$router.replace(e.url)
        }
      },
      check_tab(){
        var _this = this;
        this.items.forEach(function(el, index){
          if(el.url == _this.$route.path){
            _this.current = index + 1
          }
        })
      }
    },
    created(){
      this.check_tab()
    }
  };
  </script>

  <style scoped>
    .footer{
      position: fixed;
      bottom: 0px;
      left: 0px;
      right: 0px;
      background: #000;
    }

    @font-face{
      font-family: Mand-Mobile-Icon;
      font-style: normal;
      font-weight: 400;
      src: url(/static/icon/iconfont.woff) format("woff"),url(/static/icon/iconfont.woff) format("truetype")
    }

  </style>
