<template>
  <div class="detail">
      <div class="header">
        <md-icon class="back_btn" v-on:click="back_btn()" name="arrow-left" size="md"></md-icon>
      </div>
      <div class="content">
        <img class="thumb" :src="detail.thumb" />
        <div class="title">{{detail.title}}</div>
        <div class="desc">{{detail.desc}}</div>
      </div>
      <!--
        {"id":1,"title":"丰收的喜悦","thumb":"/static/images/digital.jpg","sale_date":"09.23 12:00 开售"}
      -->
      <div class="digital">
        <div class="digital_item" v-for="item in detail.digital_item">
          <img class="item_thumb" :src="item.thumb" />
          <div class="item_sale_date">{{item.sale_date}}</div>
          <div class="item_control">
            <div class="item_title">{{item.title}}</div>
            <md-button type="link" class="item_btn">去看看<md-icon name="arrow" size="md"></md-icon></md-button>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {Button, Icon} from 'mand-mobile'



export default {
  name: "TopicDetail",
  components: {
    [Button.name]: Button,
    [Icon.name]: Icon,
  },
  data(){
    return {
      detail: {}
    }
  },
  mounted() {
    this.detail = JSON.parse(this.$route.query.data);
    window.scrollTo(0, 0)
  },
  beforeRouteLeave(to,from,next){
    to.meta.keepAlive = true
    next(0)
  },
  methods:{
    back_btn(){
      this.$router.back()
    }
  }
};
</script>
<style>

  .detail .header{
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 1002;
    height: 100px;
    background-color: rgba(255, 255, 255, 0.8);
  }
  .detail .header .back_btn{
    margin-left: 30px;
    position: absolute;
    left: 20px;
    top: 40px;
  }

  .detail .content{
    padding: 40px;
    padding-top: 100px;
    text-align: left;
  }

  .detail .content .thumb{
    width: 100%;
    height: 100%;
  }
  .detail .content .title{
    font-size: 40px;
    font-weight: bold;
  }
  .detail .content .desc{
    font-size: 28px;
    margin-top: 30px;
  }
  .detail .digital_item{
    background-color: hsl(200, 13%, 95%);
    border-radius: 30px;
    margin: 10px 40px;
    text-align: left;
    height: 320px;
    position: relative;
  }
  .detail .digital_item .item_thumb{
    width: 280px;
    height: 280px;
    border-radius: 30px;
    position: relative;
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;
  }
  .detail .digital_item .item_sale_date{
    background: #000;
    position:absolute;
    color: #bfd7af;
    top: 225px;
    text-align: center;
    height: 90px;
    line-height: 90px;
    font-size: 30px;
    border-radius: 30px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    font-weight: bold;
    width: 280px;
  }
  .digital_item .item_control{
    font-size: 32px;
    font-weight: bold;
    position:absolute;
    text-align: center;
    left: 300px;
    top: 40px;
    width: 350px;
  }
  .digital_item .item_control .item_title{
    border-bottom: 1px solid;
    display: inline-block;
  }
  .digital_item .item_control .item_btn{
    display: block;
    margin-top: 50px;
    margin-left: 100px;
  }
</style>
