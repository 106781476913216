<template>
    <div class="banner">
      <md-swiper
        @before-change="beforeChange"
        @after-change="afterChange"
        ref="swiper"
        :is-prevent="false"
        :useNative-driver="false"
      >
        <md-swiper-item :key="$index" v-for="(item, $index) in data">
          <img class="banner-item" :src="item.thumb" :title="item.title" />
        </md-swiper-item>
      </md-swiper>
    </div>
  </template>

  <script>
  import {Swiper, SwiperItem} from 'mand-mobile'
  import simple from 'mand-mobile/components/swiper/demo/data/simple'
  export default {
    name: "Banner",
    components: {
      [Swiper.name]: Swiper,
      [SwiperItem.name]: SwiperItem,
    },
    data() {
      return {
        simple,
        data: [
            {'id': 1, 'title': '冰雪旅游', 'thumb': '/static/images/bxly.jpg'},
            {'id': 2, 'title': '查干湖冬捕', 'thumb': '/static/images/cghdb.jpg'},
            {'id': 3, 'title': '日子更红火', 'thumb': '/static/images/rzghh.jpg'},
        ],
      }
    },
    mounted() {
      // Simulation of asynchronous
      setTimeout(() => {
        this.simple = simple.concat(simple)
      }, 10000)
      // Simulation of asynchronous
      setTimeout(() => {
        this.simple = simple
      }, 24500)
      window.triggerSwiper0 = () => {
        this.goto()
      }
      window.triggerSwiper1 = () => {
        this.play()
      }
      window.triggerSwiper2 = () => {
        this.stop()
      }
    },
    methods: {
      setValue(id, value) {
        document.querySelector(id) && (document.querySelector(id).innerHTML = value)
      },
      beforeChange(from, to) {
        this.setValue('#valueSwiper0', from)
        this.setValue('#valueSwiper1', to)
      },
      afterChange(from, to) {
        this.setValue('#valueSwiper2', from)
        this.setValue('#valueSwiper3', to)
      },
      fn(index) {
        this.setValue('#valueSwiper4', index)
      },
      goto() {
        this.$refs.swiper.goto(2)
      },
      play() {
        this.$refs.swiper.play()
      },
      stop() {
        this.$refs.swiper.stop()
      },
    }
  };
  </script>

  <style scoped>
    .banner{
        margin: 40px;
        margin-top: 120px;
    }
    .banner-item{
      font-size: 30px;
      width: 100%;
      height: 100%;
      line-height: 300px;
      border-radius: 30px;
      color:#fff;
    }
  </style>
