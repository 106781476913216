<template>
    <div class="my">
      <Header></Header>

      <UserInfo></UserInfo>
      <UserMenu></UserMenu>
      <SysMenu></SysMenu>

      <FooterBar></FooterBar>
    </div>
  </template>

  <script>
  // @ is an alias to /src
  import Header from "@/components/my/Header.vue";
  import UserInfo from "@/components/my/UserInfo.vue";
  import UserMenu from "@/components/my/UserMenu.vue";
  import SysMenu from "@/components/my/SysMenu.vue";
  import FooterBar from "@/components/FooterBar.vue";

  import {Tabs, TabPane} from 'mand-mobile'
  import {ResultPage} from 'mand-mobile'



  export default {
    name: "My",
    components: {
      Header,
      UserInfo,
      UserMenu,
      SysMenu,
      FooterBar,
      [Tabs.name]: Tabs,
      [TabPane.name]: TabPane,
      [ResultPage.name]: ResultPage,
    },
    data () {
      return {
        index: 0,
      }
    }
  };
  </script>
  <style>
    .tab{
      margin:20px;
    }
    .fail_content{
      padding-top: 50px;
    }
    .my{
      padding-bottom: 100px;
    }
  </style>
