<template>
  <div class="sales_plan">
    <div class="sales" v-for="item in item_data">
      <div class="sale_date">{{item.sale_date}}</div>
      <div class="sale_time">{{item.sale_time}}</div>
      <div class="digital_item" v-for="digital in item['digital_item']">
        <img :src="digital.thumb" class='thumb' />
        <span class="title">
          {{digital.title}}&nbsp;
          <md-tag size="large" shape="fillet" type="ghost" fill-color="rgba(255, 91, 96, .1)" font-weight="normal"
            font-color="#FF5B60">{{digital.author_type}}</md-tag>
        </span>
        <span class="item_tag2">
          {{digital.item_type}}
        </span>
        <span class="item_quantity">
          <span>限量</span>
          <span>{{digital.quantity}}份</span>
        </span>
        <span class="item_price">
          &yen;<md-amount :value="digital.price"></md-amount>
        </span>
        <span class="item_btn">
          <md-button class="item_btn" type="primary" size="small" round>敬请期待</md-button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon, Tag, Button, Steps, Amount } from 'mand-mobile'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: "SalesPlan",
  components: {
    [Icon.name]: Icon,
    [Tag.name]: Tag,
    [Button.name]: Button,
    [Steps.name]: Steps,
    [Amount.name]: Amount,
    'font-awesome-icon': FontAwesomeIcon
  },
  data() {
    return {
      item_data: [
        {
          'sale_date': '10月10日',
          'sale_time': '12:00',
          'digital_item': [
            { 'id': 1, 'title': '万物复苏', 'thumb': '/static/images/wwfs1b1.jpeg', 'author_type': '个人', 'item_type': '权益盲盒', 'quantity': 1000, 'price': 18 },
            { 'id': 1, 'title': '时光荏苒', 'thumb': '/static/images/sgrr.jpeg', 'author_type': '个人', 'item_type': '权益盲盒', 'quantity': 10, 'price': 99 },
          ]
        },
        {
          'sale_date': '10月25日',
          'sale_time': '20:00',
          'digital_item': [
            { 'id': 1, 'title': '金碧辉煌', 'thumb': '/static/images/jbhh.jpeg', 'author_type': '个人', 'item_type': '权益盲盒', 'quantity': 1000, 'price': 59 },
            { 'id': 1, 'title': '静', 'thumb': '/static/images/jing.jpeg', 'author_type': '企业', 'item_type': '农产品盲盒', 'quantity': 500, 'price': 99 },
          ]
        }
      ],
    }
  },
  methods: {
    greet() {
      Toast.info("I'm Batman!");
    }
  }
};
</script>

<style scoped>
.sales_plan {
  padding: 40px;
  padding-bottom: 0px;
  text-align: left;
}

.sales_plan .sales{
  margin-bottom: 60px;
}
.sales_plan .sale_date {
  font-weight: bold;
  font-size: 32px;
  margin-left: -20px;
}

.sales_plan .sale_time {
  font-size: 32px;
  font-weight: 500;
  margin-left: -20px;
}

.sales_plan .custom-content {
  height: 300px;
}

.digital_item {
  border-radius: 20px;
  margin-top: 10px;
  background-color: hsl(200, 13%, 95%);
  width: 650px;
  height: 220px;
  position:relative;
  margin-top: 30px;
}

.digital_item .thumb {
  width: 220px;
  height: 220px;
  border-radius: 20px;
}

.digital_item .title {
  text-align: left;
  font-size: 32px;
  font-weight: bold;
  position: absolute;
  margin-left: 20px;
  margin-top: 20px;
}

.digital_item .item_quantity {
  font-size: 26px;
  width: 200px;
  text-align: left;
  position: absolute;
  margin-top: 80px;
  margin-left: 20px;
}

.digital_item .item_quantity span {
  padding: 10px;
}

.digital_item .item_quantity span:last-child {
  display: inline-block;
  background-color: #4c4c4e;
  color: #eee0c0;
}

.digital_item .item_quantity span:first-child {
  display: inline-block;
  background: #eee0c0;
  color: #4c4c4e;
}

.digital_item .item_tag2 {
  background: rgba(0, 0, 0, 0.8);
  color: #bfd7af;
  position: absolute;
  height: 50px;
  line-height: 60px;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 55px;
  font-size: 24px;
  width: 200px;
  text-align: center;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  font-weight: bold;
  left: 0px;
  margin-top: 0px;
}

.digital_item .item_price {
  font-size: 32px;
  font-weight: bold;
  text-align: right;
  position: absolute;
  margin-top: 170px;
  margin-left: 20px;
}

.digital_item .item_btn {
  width: 200px;
  right: 10px;
  bottom: 10px;
  position: absolute;
}
</style>
